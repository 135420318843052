<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <auth-card>
    <!--<auth-logo />-->
    <v-card-title>{{ $t('login') }}</v-card-title>
    <v-card-subtitle>{{ $t('loginPage.login_subtitle') }}</v-card-subtitle>
    <v-card-text>
      <app-form
        ref="form"
        v-slot="{invalid}"
        :errors="errors"
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row v-if="loginWithUsername">
            <app-text-input
              v-model="form.login_id"
              cols="12"
              name="login_id"
              required
            />
            <app-password-input
              v-model="form.password"
              cols="12"
              hide-icon
              name="password"
              required
            />
          </v-row>
          <v-row v-else>
            <app-number-input
              v-model="form.login_id"
              :label="parseAttribute('mobile')"
              cols="12"
              name="login_id"
              persistent-placeholder
              placeholder="5xxxxxxxx"
              required
            />
          </v-row>
          <!--          <v-row no-gutters>
            <v-col cols="auto">
              <router-link
                :to="route(APP_ROUTES.auth.forgotPassword)"
                class="text-decoration-underline font-weight-bold"
              >
                {{ $t('loginPage.forget_password') }}
              </router-link>
            </v-col>
          </v-row>-->
          <v-row dense>
            <v-col cols="12">
              <app-btn
                :block="AppIsSmall"
                :disabled="invalid"
                :loading="loading"
                @click="submit"
              >
                {{ $t('login') }}
              </app-btn>
            </v-col><!--
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <app-btn
                :disabled="loading"
                plain
                text
                @click="loading ? undefined : loginWithUsername = !loginWithUsername"
              >
                {{ $t('loginPage.login_with', { name: parseAttribute(loginTypeReverse) }) }}
              </app-btn>
            </v-col>-->
          </v-row>
          <!--          <v-row>
            <v-col cols="12">
              <p class="ma-0">
                <span>{{ $t('loginPage.no_account') }}</span>
                <router-link
                  :to="route(APP_ROUTES.auth.register)"
                  class="text-decoration-underline font-weight-bold"
                >
                  {{ $t('loginPage.create_account') }}
                </router-link>
              </p>
            </v-col>
          </v-row>-->
        </v-container>
      </app-form>
    </v-card-text>
    <!--    <v-divider />
    <v-card-actions>
      <app-btn :to="route(APP_ROUTES.homePage)">
        {{ $t('home') }}
      </app-btn>
    </v-card-actions>-->
  </auth-card>
</template>

<script>

import AuthCard from '@components/auth/AuthCard'

export default {
  name: 'Login',
  components: { AuthCard },
  data () {
    return {
      text: '',
      loading: !1,
      errors: {},
      form: {
        login_id: '',
        password: ''
      },
      loginWithUsername: !0
    }
  },
  computed: {
    disableLogin () {
      if (this.loginWithUsername) return !(this.form.login_id && this.form.password)

      return this.form.login_id
    },
    loginTypeReverse () {
      return !this.loginWithUsername ? 'login_id' : 'mobile'
    },
    loginType () {
      return this.loginWithUsername ? 'login_id' : 'mobile'
    }
  },
  watch: {
    loginWithUsername () {
      this.form = {
        login_id: '',
        password: ''
      }
    }
  },
  mounted () {
    if (this.authUser) {
      // this.navigate(this.APP_ROUTES.homePage)
    }
    // console.log(this.$vuetify.breakpoint)
  },
  methods: {
    async submit () {
      const form = { ...this.form, push_token: window.push_token || null }
      // console.log(form)
      // if (this.loading || this.disableLogin) return
      if (this.loading) return
      this.loading = !0

      if (this.IsMobileApp) {
        form.is_mobile = !0
      }

      this.errors = {}
      try {
        const { data, _data: user, _success } = await this.apiService.auth.login(form)
        const token = data?.token
        if (_success === !0 && user) {
          if (token) {
            this.loginUser({ token, user })
          } else if (user.factorToken) {
            this.goToOtpPage(data)
          }
        }
      } catch (e) {
        // alert(e)
        const { _message, _errors } = e
        this.errors = _errors || {}
        this.form.password = ''
        const message = _message || e.message
        // message && this.alertError(JSON.stringify(Object.keys(e.request)))
        // this.alertError(JSON.stringify(e))
        message && this.alertError(message)
      } finally {
        this.loading = !1
      }
    },
    goToOtpPage (response) {
      const { data } = response || {}
      this.$router.push(this.route(this.APP_ROUTES.auth.otp, {
        mobile: this.form.login_id,
        token: data?.factorToken,
        resendTime: data.resend_time,
        label: response.message
      }))
    }
  }
}
</script>
